<template>
    <div class="contenter" style="height:100%;padding-top:88px;padding-bottom:54px;">
        <Header style="margin-top:-88px;position: relative;"></Header>
        <div class="mainInfo">
            <h3>关于EPS DATA新版网站正式上线运行的公告</h3>
            <p class="titPear">尊敬的各位用户：</p>
            <p class="conPear">特别感谢大家长期以来对EPS DATA的信任和支持！</p>
            <p class="conPear">为使广大用户更加全面的了解EPS DATA并获得更加优质的产品体验，经过精心的策划和筹备，EPS DATA新版官方网站已于2020年7月12日正式上线运行。同时，我们也对“EPS 数据平台”、“EPS微观经济数据查询系统”等产品线进行全面的改版升级，新版网站访问网址为：<a href="https://www.epsnet.com.cn" target="_blank" title="https://www.epsnet.com.cn">https://www.epsnet.com.cn</a>；希望通过新版网站的全新上线能够使广大用户更加便捷与深入地了解和体验EPS DATA所带来的真正价值。</p>
            <p class="conPear">新版EPS DATA官方网站从结构设计、页面布局、交互样式、内容呈现、视觉风格、功能性与信息安全等方面进行全方位的改进与优化，接入EPS DATA所有产品线，更好的展现EPS DATA发展面貌，也为用户带来更加实用的产品体验与更加美观的视觉感受。</p>
            <p class="conPear">一直以来，EPS DATA非常重视用户需求，致力于深挖数据背后的价值并以多元化的数据产品与数据服务满足各类数据需求。在此次网站改版过程中，我们对EPS DATA的核心产品——EPS数据平台增加了身份认证体系，拓宽用户的校外访问途径，满足用户在疫情影响下的校外访问需求。未来，我们将对EPS DATA所有产品线提供校外认证访问方式，以保障高校师生教学科研活动不受影响。</p>
            <p class="conPear">另外，此次我们也对EPS DATA多条产品线的页面样式、功能架构、交互设计、排序算法、搜索效率等方面进行较大幅度的优化升级，期待可以为您带来更加便捷、高效、愉悦的产品体验。</p>
            <p class="conPear">新版网站目前处于试运行阶段，我们热忱欢迎广大用户对新版网站提出宝贵意见和建议，对网站中存在的问题和疏漏进行批评指正，我们将根据您的意见继续完善网站功能，充实网站内容。欢迎各位用户通过微信公众号留言，也可拨打客服电话(010-85786021-8001)联系我们，以便我们做得更好。</p>
            <p class="conPear">再一次感谢您对EPS DATA一如既往的支持！</p>
            <p class="conRight">EPS DATA团队</p>
            <p class="conRight">2020年7月12日</p>
        </div>
        <Footer></Footer>
    </div>
</template>
<script>
import Header from "./commons/Header.vue";
import Footer from "./commons/Footer.vue";

export default {
  name: "info",
  components: {
    Header,
    Footer
  },
  data() {
    return {
    };
  },
  computed: {},
  methods: {
  },
  mounted() {
  }
};
</script>
<style lang="scss">
.mainInfo{
    min-height:100%;
    width:100%;
    margin:10px 0;
    h3{
        width:1200px;
        text-align: center;
        line-height:80px;
        font-size:24px;
        padding-top:20px;
        margin:0 auto;
    }
    p{
        width:1200px;
        margin:0 auto;
        font-size:16px;
        text-align: left;
        line-height:36px;
    }
    .titPear{
        font-weight: bold;
    }
    .conRight{
        text-align: right;
    }
    .conPear{
        margin-top:10px;
        text-indent:2em;
        a{
            color:#2a83d3;
        }
    }
}
</style>